import { allowWindowScroll } from "./header";

document.addEventListener("DOMContentLoaded", afterDOMContentLoaded);

function afterDOMContentLoaded() {
  const bgCont = document.getElementById("bg-cont");

  handleScroll(bgCont);
  document.addEventListener("scroll", () => handleScroll(bgCont), {
    passive: true,
  });
}

function handleScroll(bgCont: HTMLElement) {
  if (!allowWindowScroll) return;

  const scrollTop = window.scrollY;
  const docHeight = document.body.offsetHeight;
  const winHeight = window.innerHeight;
  const scrollPercent = scrollTop / (docHeight - winHeight);
  let scrollPercentRounded = Math.round(scrollPercent * 100);
  if (scrollPercentRounded > 100) {
    scrollPercentRounded = 100;
  }

  bgCont.style.transform = `translateX(-${scrollPercentRounded}px) translateY(-${
    scrollPercentRounded * 2
  }px)`;
}
